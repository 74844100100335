.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: var(--button-text-color-primary);

  &:hover {
    cursor: pointer;  
  }

  &:disabled {
    cursor: not-allowed;
  }
  
  svg {
    color: inherit;
  }
}

/* button variants */
.icon-button-primary {
  background-color: var(--button-background-primary);

  & svg {
    color: var(--text-color-white);
  }

  &:hover {
    background-color: var(--button-background-primary-hover);
  }

  &:focus {
    border: 2px solid var(--button-border-primary-focus);
    background-color: var(--button-background-primary-focus);
  }

  &:active {
    background-color: var(--button-background-primary-active);
  }

  &:disabled {
    background-color: var(--button-background-primary-disabled);
  }
}

.icon-button-secondary {
  background-color: var(--button-background-secondary);
  color: var(--button-text-color-secondary);

  &:hover {
    background-color: var(--button-background-secondary-hover);
  }

  &:focus {
    border: 1px solid var(--button-border-secondary-focus);
    background-color: var(--button-background-secondary-focus);
  }

  &:active {
    background-color: var(--button-background-secondary-active);
    color: var(--button-text-color-secondary-active);
  }

  &:disabled {
    background-color: var(--button-background-secondary-disabled);
    color: var(--button-text-color-secondary-disabled);
  }
}

.icon-button-outline {
  border: 1px solid var(--button-border-outline);
  color: var(--button-text-color-outline);

  &:hover {
    border: 1px solid var(--button-border-outline-hover);
    background-color: var(--button-background-outline-hover);
    color: var(--button-text-color-outline-hover);
  }

  &:focus {
    border: 2px solid var(--button-border-outline-focus);
  }

  &:active {
    border: 1px solid var(--button-border-outline-active);
    background-color: var(--button-background-outline-active);
    color: var(--button-text-color-outline-active);
  }

  &:disabled {
    border: 2px solid var(--button-border-outline-disabled);
    color: var(--button-text-color-outline-disabled);
  }
}

.icon-button-tertiary {
  color: var(--button-text-color-tertiary);
  
  &:hover {
    background-color: var(--button-background-tertiary-hover) ;
  }

  &:focus {
    border: 1px solid var(--button-border-tertiary-focus);
    background-color: var(--button-background-tertiary-focus);
    color: var(--button-text-color-tertiary-focus);
  }
  
  &:active {
    background-color: var(--button-background-tertiary-active) ;
  }
  
  &:disabled {
    color: var(--button-text-color-tertiary-disabled);
  }
}

.icon-button-destructive {
  background-color: var(--button-background-destructive);

  &:hover {
    background-color: var(--button-background-destructive-hover);
  }

  &:focus {
    border: 2px solid var(--button-border-destructive-focus);
    background-color: var(--button-background-destructive-focus);
  }

  &:active {
    background-color: var(--button-background-destructive-active);
  }

  &:disabled {
    background-color: var(--button-background-destructive-disabled);
  }
}

.icon-button-destructive-secondary {
  background-color: var(--button-background-destructive-secondary);
  color: var(--button-text-color-destructive-secondary);

  &:hover {
    background-color: var(--button-background-destructive-secondary-hover);
  }

  &:focus {
    border: 1px solid var(--button-border-destructive-secondary-focus);
    background-color: var(--button-background-destructive-secondary-focus);
  }

  &:active {
    background-color: var(--button-background-destructive-secondary-active);
    color: var(--button-text-color-destructive-secondary-active);
  }

  &:disabled {
    background-color: var(--button-background-destructive-secondary-disabled);
    color: var(--button-text-color-destructive-secondary-disabled);
  }
}

.icon-button-destructive-outline {
  border: 1px solid var(--button-border-destructive-outline);
  background-color: transparent;
  color: var(--button-text-color-destructive-outline);

  &:hover {
    border: 1px solid var(--button-border-destructive-outline-hover);
    background-color: var(--button-background-destructive-outline-hover);
  }

  &:focus {
    border: 2px solid var(--button-border-destructive-outline-focus);
    color: var(--button-text-color-destructive-outline-focus);
  }

  &:active {
    border: 1px solid var(--button-border-destructive-outline-active);
    background-color: var(--button-background-destructive-outline-active);
    color: var(--button-text-color-destructive-outline-active);
  }

  &:disabled {
    border: 1px solid var(--button-border-destructive-outline-disabled);
    color: var(--button-text-color-destructive-outline-disabled);
  }
}

.icon-button-destructive-tertiary {
  color: var(--button-text-color-destructive-tartiary);

  &:hover {
    background-color: var(--button-background-destructive-tartiary-hover);
  }

  &:focus {
    border: 1px solid var(--button-border-destructive-tartiary-focus);
  }

  &:active {
    background-color: var(--button-background-destructive-tartiary-active);
    color: var(--button-text-color-destructive-tartiary-active);
  }

  &:disabled {
    color: var(--button-text-color-destructive-tartiary-disabled);
  }
}

/* link variants */
.icon-button-link {
  color: var(--link-text-color-primary);

  &:hover {
    color: var(--link-text-color-primary-hover);
  }

  &:focus {
    color: var(--link-text-color-primary-focus);
  }

  &:active {
    color: var(--link-text-color-primary-active);
  }

  &:disabled {
    color: var(--link-text-color-primary-disabled);
  }
}

.icon-button-large {
  width: 3rem;
  height: 3rem;
}

.icon-button-medium {
  width: 2.5rem;
  height: 2.5rem;
}

.icon-button-small {
  width: 2.25rem;
  height: 2.25rem;
}

.icon-button-square {
  padding: 0.5rem;
  border-radius: 10px;
}

.icon-button-round {
  border-radius: 50%;
}