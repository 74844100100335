.drawer {
  width: 328px;
  overflow-x: hidden;

  &.drawer-mobile {
    top: var(--header-height);
    padding-bottom: var(--header-height);
    width: 100%;
  }
}

.filters-mobile {
  position: absolute;
  top: 24px;
  right: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-right-color);
  border-radius: 8px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.filters-mobile:has(button.active) {
  border: 1px solid var(--button-border-secondary-focus);
}

.filters-mobile button:focus {
  border: none;
}

.main {
  flex-grow: 1;
  height: auto;
}

.filters {
  position: sticky;
  top: 0;
  min-width: 360px;
  max-width: 360px;
  height: auto;
}

@media (width < 1280px) {
  .filters-mobile {
    display: flex;
  }
  
  .filters {
    display: none;
  }
}

@media (width <= 1023px) {
  .filters-mobile {
    top: 16px;
    right: 16px;
  }
}

@media (width <= 767px) {
  .filters-mobile {
    top: 20px;
    right: 10px;
  }
}
