.list {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  height: 27px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (width <= 767px) {
  .item:not(:nth-last-child(2)) {
    display: none;
  }

  .item:nth-last-child(2) .icon {
    transform: rotate(180deg);
  }

  .list {
    margin-bottom: 20px;
  }
}