@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url('./normalize.css');
@import url('./variables/pallete.css');
@import url('./variables/button-colors.css');
@import url('./variables/themes.css');
@import url('./variables/main-variables.css');

body {
  min-height: 100vh;
  background-color: var(--background-app);
  line-height: 140%;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
  font-family: var(--font-family);
  user-select: none;
}

li {
  list-style: none;
}

input, textarea {
  user-select: text;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--border-color);
  transition: background-color 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-black-300);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-purple-400);
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

div[data-floating-ui-portal] > div > div {
  max-height: 380px;
  overflow-y: scroll;
}

.container {
  flex: 1 1 auto;
  margin: 0 auto var(--container-m-b-desktop);
  width: 100%;
  max-width: var(--container-width);
}

@media screen and (width <= 1439px) {
  .container {
    padding: 0 30px;
  }
}

@media screen and (width <= 1023px) {
  .container {
    margin-bottom: var(--container-m-b-tablet);
  }
}

@media screen and (width <= 992px) {
  .container {
    max-width: var(--container-width-tablet);
  }
}

@media screen and (width <= 767px) {
  .container {
    margin-bottom: var(--container-m-b-mobile);
    padding: 0 10px;
    max-width: var(--container-width-sm);
  }
}

@media screen and (width <= 576px) {
  .container {
    max-width: var(--container-width-mobile);
  }
}
