.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 16px 0;
  width: 100%;
}

.header-nav {
  align-items: center;
  gap: 52px;
}

.header-background {
  height: 77px;
  overflow: hidden;
  border-bottom: 1px solid var(--color-black-50);
  background-color: var(--color-white-900)
}

.links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.questions-link {
  display: inline-flex;
  align-items: start;
  padding: 6px 10px;
  border-radius: 12px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.questions-link.active {
  background-color: var(--color-black-30);
}

.questions-link.active > * {
  color: var(--color-purple-800);
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin: 8px 4px;
  width: 100%;
  max-width: 276px;
}

@media screen and (width <=1023px) {
  .header {
    margin-bottom: 24px;
  }

  .header-nav {
    gap: 32px;
  }
}

@media (width <= 767px) {
  .header {
    padding: 10px 0;
  }

  .header-nav {
    gap: 20px;
  }
}



