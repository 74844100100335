.progress-bar-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.progress-bar-item {
  display: block;
  flex-basis: 33%;
  height: 8px;
  background-color: var(--color-black-50);
}

.progress-bar-item:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.progress-bar-item:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.progress-bar-item:not(:last-of-type) {
  margin-right: 1px;
}

.progress-bar-item-large {
  height: 12px;
}

.progress-bar-item-checked {
  background-color: var(--color-green-900);
}