.header {
  position: relative;
}

.header-params {
  gap: 40px;	
}

.popup {
  position: relative;
  top: 8px;
  left: 15px;
  padding: 6px;
  border-radius: 20px;
}

.image-wrapper {
  margin-bottom: 24px;
  max-width: 475px;
  overflow: hidden;
  border-radius: 24px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  justify-content: end;
}

@media screen and (width <= 767px) {
  .item {
    gap: 20px;
  }
  
  .header-params {
    gap: 16px;
  }
}