.doc-item {
  gap: 8px;
}

.doc-item-name {
  max-width: 392px;
  color: var(--text-color-primary);
}

@media (width <= 469px) {
  .doc-item {
    flex-direction: column;
    gap: 0;
  }

  .doc-item-name {
    margin-bottom: 16px;
  }

  .doc-link {
    justify-content: end;
    align-self: flex-end;
  }
}