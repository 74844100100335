.accordion {
  padding: 24px 0;
  border-bottom: 1px solid var(--border-color);
  transition: padding 0.2s ease-in-out;
}

.accordion.accordion-opened {
  padding-bottom: 0;
}

.heading {
  margin: 0;
  font-size: var(--font-size-h-xs);
  transition: margin-bottom 0.3s ease-in-out;
}

.heading.accordion-opened {
  margin-bottom: 20px;
  transition: margin-bottom 0.3s ease-in-out;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border: none;
  background-color: transparent;
  color: var(--text-color-bright);
  cursor: pointer;
}

.title {
  position: relative;
  display: flex;
  align-items:normal;
  padding-left: 16px;
  text-align: initial;
  transition: color 0.3s ease-in-out;
    
}

.title::before {
  position: absolute;
  top: 7px;
  left: 0;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--text-color-link-active);
}

.title.skeleton::before {
  display: none;
}

.icon {
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
}

.icon.accordion-opened {
  transform: rotate(-180deg);
}

.content-wrapper {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.content {
  padding: 0 0 16px 24px;
  color: var(--text-color-light);
}


@media (width <= 767px) { 
  .accordion {
    padding: 20px 0;
  }

  .content {
    padding-left: 16px;
  }
}