.footer * {
  margin: 0;
  padding: 0;
}

.footer {
  padding: 64px 112px 18px;
  border-radius: 20px 20px 0 0;
  background: var(--background-footer);
  color: var(--text-color-white);
}

.container-logo {
  display: flex;
  align-items: start;
  margin-bottom: 16px;
  width: 100%;
  height: 32px;
}

.container-logo a img{
  margin-right: 5px;
}

.subtitle {
  margin-bottom: 20px;
  max-width: 290px;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-m);
  white-space: pre-wrap;
}

.container-social {
  display: flex;
  gap: 20px;
  margin-bottom: 64px;
  width: 100%;
  max-width: 174px;
  height: 28px;
}

.container-social svg  {
  width: 28px;
  height: 28px;
  fill: white;
}

.about {
  margin-bottom: 30px;
  color: var(--text-color-the-lightest);
  line-height: 15px;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-xs);
}

.footer hr {
  margin-bottom: 20px;
}

.copyright-icons {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;
}

.company-name {
  color: var(--text-color-the-lightest);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}

.social-media-icon-wrapper {
  aspect-ratio: 1 / 1;
  height: 30px;
}

.docs {
  margin-right: 20px;
  color: var(--color-black-400);
  line-height: 18px;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
  font-family: var(--font-family);
}

.figma {
  width: 20px;
  height: 20px;
}

.github-icon {
  width: 29px;
  height: 28px;
}

.copyright-icons svg {
  fill: white;
}

.figma :nth-child(1) {
  fill:#00BCFF;
}

.figma :nth-child(2) {
  fill:#00CF7F;
}

.figma :nth-child(3) {
  fill:#FF7361;
}

.figma :nth-child(4) {
  fill:#FF4D12;
}

.figma :nth-child(5) {
  fill:#B659FF;
}

@media screen and (width <= 1439px) {
  .footer {
    padding: 37px 0 18px;
  }

  .container {
    max-width: var(--container-width-lg);
  }

  .about {
    margin-bottom: 16px;
  }

  .footer hr {
    margin-bottom: 15px;
  }
}

@media (width <= 992px) {
  .container {
    max-width: var(--container-width-tablet);
  }

  .container-social {
    margin-bottom: 47px;
  }
}

@media (width <= 767px) {
  .footer {
    padding: 19px 16px 18px;
  }

  .container {
    max-width: var(--container-width-sm);
  }

}

@media (width <= 576px) {
  .container-logo {
    margin-bottom: 11px;
  }

  .subtitle {
    max-width: 100%;
  }

  .container-social {
    margin-bottom: 12px;
  }
}

@media  (width <= 360px) {
  .github-icon, .figma{
    width: auto;
    height: 24px;
  }

  .container-social {
    gap: 16px;
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: var(--font-size-p-s);
  }

  .docs {
    margin-right: 14px;
  }
}
