.sidebar {
  width: 280px;
  height: 100%;
  background: var(--background-block);
  transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 76px;
  border-bottom: 1px solid var(--border-color);
  transition: border-color 0.3s ease-in-out;
}

.close-icon {
  position: absolute;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  background-color: var(--color-white-900);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.arrow {
  width: 18px; 
  height: 18px; 
  transform: rotate(180deg);
}

.arrow path {
  fill: var(--color-black-600);
}

.left {
  transform: rotate(-180deg);
}

.menu {
  padding: 36px 20px 20px;
}

.closing {
  width: 88px;
}

.bottom-actions {
  margin-top: auto;
  padding: 20px;
}

.bottom-actions-skeleton {
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: inherit;
}

.sidebar-bottom-button {
  min-width: auto;
  height: 48px;
  transition: all 0.4s;
}

.sidebar-bottom-button-hide {
  padding: 0;
}

.sidebar-bottom-button > svg {
  width: 24px;
  height: 24px;
}

.sidebar-bottom-button-hide > span {
  position: absolute;
  z-index: -1;
  margin: 0;
  min-width: 0;
  transition: all 0.3s;
  opacity: 0;
  pointer-events: none;
}

@media (width < 1024px) {
  .close-icon {
    display: none;
  }

  .desktop-sidebar {
    display: none;
  }
}