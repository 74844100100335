.header {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  border-bottom: 1px solid var(--border-color);
  transition: border-color 0.3s ease-in-out;
}

.burger {
  display: none; 
  padding: 0;
}

.logo {
  display: none; 
}

@media (width < 1024px) {
  .burger {
    display: block;
  }

  .header {
    justify-content: space-between;
    padding: 15px 20px;
  }
	
  .logo {
    display: block;
  }
}

@media (width <= 767px) {
  .header {
    justify-content: space-between;
    padding: 10px;
  }
}

@media (width < 480px) {
  .header {
    border-bottom: 1px solid transparent;
  }
}