.header {
  padding: 24px;
}

.title {
  flex: 1;
}

.image-wrapper {
  display: flex;
  margin-right: 10px;
  min-width: 170px;
  min-height: 114px;
}

.image-wrapper-mobile {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; /* Отступ перед текстом */
  width: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: inherit;
}

.image-default {
  width: 170px;
  height: 114px;
  border-radius: 12px;
}

.image-mobile {
  width: 100%;
  height: 307px;
  border-radius: 8px;
  object-fit: contain;
}

.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 2px 8px;
  width: auto;
  max-height: 20px;
  border-radius: 30px;
  background-color: var(--color-green-007);
}

.label {
  color: var(--color-green-750);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-xs);
}

@media (width < 1024px) {
  .header {
    padding: 16px;
  }
}

@media (width < 768px) {
  .header {
    padding: 20px 10px;
  }
	
  .question-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
	
  .title-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    max-width: 100%;
  }
 
  .label-wrapper {
    display: flex;
  }
}
 
@media (width <=564px) {
  .title-wrapper {
    display: flex;
    margin-left: 0;
  }
}