/* stylelint-disable selector-class-pattern */
.popover-calendar button:focus {
  border: none;
}

.calendar-block {
  margin-bottom: auto;
  height: auto;
  
  .react-calendar {
    margin: auto;
    border: none;
    font-family: var(--font-family);
  }
  
  .react-calendar__navigation {
    margin-bottom: 8px;
    height: 56px;
    border-bottom: 1px solid var(--border-color);
    
    button {
      padding-bottom: 23px;
      color: var(--text-color-bright);
      font-size: var(--font-size-p-l);
      
      &::first-letter {
        text-transform: capitalize;
      }

      &:disabled {
        background-color: var(--background-block);
      }
      
      &:enabled:hover,
      &:enabled:focus {
        background-color: var(--background-block);
      }
    }
  }

  .drawer {
    width: 328px;
  
    &.drawer-mobile {
      top: var(--header-height);
      padding-bottom: var(--header-height);
      width: 100%;
      
      .calendar-block {
        width: 100%;
      }
    }
  }
  
  .react-calendar__month-view__weekdays {
    color: var(--text-color-lightest);
    text-transform: none;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-p-s);

    abbr {
      text-transform: none;
      text-decoration: none;
    }
  }

  .react-calendar__tile--now {
    background: var(--background-block);
    text-transform: none;

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--background-block);
    }

    abbr {
      background: var(--background-progress-bar);
      text-transform: none;
    }
  }

  .react-calendar__tile--active {
    background-color: var(--background-block);
    text-transform: none;

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--background-block);
    }

    abbr {
      background-color: var(--text-color-link-active);
      color: var(--background-block);
      text-transform: none;
    }
  }

  .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    color: var(--text-color-primary);
    text-transform: none;
    font-size: var(--font-size-p-s);

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--background-block);
    }

    abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85%;
      height: 24px;
      border-radius: 50%;
      text-transform: none;
      font-size: var(--font-size-p-m);

      &:hover {
        background-color: var(--background-progress-bar);
      }
    }
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--background-block);

    abbr {
      background-color: var(--background-progress-bar);
    }
  }
}

.popover-calendar {
  position: absolute;
  top: 16px;
  right: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-right-color);
  border-radius: 8px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.popover-calendar:has(button.active) {
  border: 1px solid var(--button-border-secondary-focus);
}


.popover-calendar-info-wrapper {
  width: 100%;
  max-width: 314px;
}

@media (width < 1280px) {
  .popover-calendar {
    display: flex;
  }
  
  .additional-info-wrapper {
    display: none;
  }
}

@media (width < 480px) {
  .popover-calendar {
    top: 10px;
  }

  .calendar-block {
    width: 320px;
    min-width: 320px;
  }
}
