.home-link {
  display: flex;
  align-items: center;
  gap: 4px;
  width: auto;
  transition: padding-left 0.5s ease-in-out;
}

.logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.logo-text {
  display: block;
  width: 139px;
  height: 45px;
}

.white > path {
  fill: var(--color-white-900);
}

.black > path {
  fill: var(--color-black-800);
}

.center {
  transition: padding-left 0.4s ease-in-out;
}

.pointer-event-none {
  pointer-events: none;
}

@media (width <=767px) {
  .logo-text {
    display: none;
  }
}
