.flex {
  display: flex;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-normal {
  align-items: normal;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap10 {
  gap: 10px;
}

.gap12 {
  gap: 12px;
}

.gap14 {
  gap: 14px;
}

.gap16 {
  gap: 16px;
}

.gap20 {
  gap: 20px;
}

.gap24 {
  gap: 24px;
}

.gap28 {
  gap: 28px;
}

.gap32 {
  gap: 32px;
}

.gap40 {
  gap: 40px;
}

.gap48 {
  gap: 48px;
}

.gap60 {
  gap: 60px;
}

.gap120 {
  gap: 120px;
}

.flex1 {
  flex: 1;
}

.max-width {
  width: 100%;
}

.max-height {
  height: 100%;
}